<template>
  <div v-if="model">
    <h5 class="info-text">Let's start with the basic information</h5>
    <div class="row">
      <div class="form-group col-4" v-if="false">
        <label>User Id</label>
        <fg-input name="user_id" placeholder="User Id" v-model="model.user_id" readonly></fg-input>
      </div>

      <div class="form-group col-4" v-if="false">
        <label>User Id</label>
        <fg-input name="user_id" placeholder="User Id" v-model="model.patient_id" readonly></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Name</label>
        <fg-input
          type="text"
          name="name"
          placeholder="Name"
          v-model="model.name"
          v-validate="modelValidations.name"
          :error="getError('name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Phone</label>
        <fg-input
          type="number"
          name="phone"
          placeholder="Phone"
          v-model="model.phone"
          v-validate="modelValidations.phone"
          :error="getError('phone')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Alternative Phone</label>
        <fg-input
          type="number"
          name="alt_phone"
          placeholder="Alternative Phone"
          v-model="model.alt_phone"
          v-validate="modelValidations.alt_phone"
          :error="getError('alt_phone')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Email</label>
        <fg-input
          type="email"
          name="email"
          placeholder="Email"
          v-model="model.email"
          v-validate="modelValidations.email"
          :error="getError('email')"
        ></fg-input>
      </div>
      
      <div class="form-group col-4">
        <label for>Gender</label>
        <fg-input
          name="gender"
          v-model="model.gender"
          v-validate="modelValidations.gender"
          :error="getError('gender')"
        >
          <el-select
          name="gender"
          class="select-defaul"
          placeholder="Select Gender"
          v-model="model.gender"
        >
          <el-option
            v-for="option in genders"
            class="select-success"
            :value="option"
            :label="option"
            :key="option"
          ></el-option>
        </el-select>
        </fg-input>
      </div>

      <div class="form-group col-3">
        <label for>DOB</label>
        <fg-input
          name="dob"
          v-model="model.dob"
          type="date"
          placeholder="Pick DOB"
          v-validate="modelValidations.dob"
          :error="getError('dob')"
        >
          <el-date-picker
            name="dob"
            v-model="model.dob"
            type="date"
            placeholder="Pick DOB"
            v-validate="modelValidations.dob"
          ></el-date-picker>
        </fg-input>
      </div>

      <div class="form-group col-1">
        <label for>Age</label>
        <fg-input
          readonly
          type="number"
          name="age"
          placeholder="Age"
          v-model="age"
        ></fg-input>
      </div>

      <div class="form-group col-4" v-if="false">
        <label for>Father's Name</label>
        <fg-input
          type="text"
          name="father_name"
          placeholder="Father's Name"
          v-model="model.father_name"
          v-validate="modelValidations.father_name"
          :error="getError('father_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4" v-if="false">
        <label for>Mother's Name</label>
        <fg-input
          type="text"
          name="mother_name"
          placeholder="Mother's Name"
          v-model="model.mother_name"
          v-validate="modelValidations.mother_name"
          :error="getError('mother_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4" v-if="false">
        <label for>Spouse's Name</label>
        <fg-input
          type="text"
          name="spouse_name"
          placeholder="Spouse's Name"
          v-model="model.spouse_name"
          v-validate="modelValidations.spouse_name"
          :error="getError('')"
        ></fg-input>
      </div>

      <div class="form-group col-4" v-if="false">
        <label for>Maritial Status</label>
        <el-select
          name="maritial_status"
          class="select-defaul"
          placeholder="Maritial Status"
          v-model="model.maritial_status"
        >
          <el-option
            v-for="option in maritial_statuses"
            class="select-success"
            :value="option"
            :label="option"
            :key="option"
          ></el-option>
        </el-select>
      </div>

      

      <!-- <div class="form-group col-4">
        <label for>Password</label>
        <fg-input
          type="password"
          name="password"
          placeholder="Password"
          v-model="model.password"
          v-validate="modelValidations.password"
          :error="getError('password')"
        ></fg-input>
      </div> -->

      <div class="form-group col-4">
        <label>Ref Name</label>
        <fg-input
          type="text"
          name="ref_name"
          v-validate="modelValidations.ref_name"
          :error="getError('ref_name')"
          v-model="model.ref_name"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label>Ref Contact</label>
        <fg-input
          type="number"
          name="ref_contact"
          v-validate="modelValidations.ref_contact"
          :error="getError('ref_contact')"
          v-model="model.ref_contact"
        ></fg-input>
      </div>


    </div>
  </div>
</template>
<script>
import { Upload, DatePicker, Select, Option } from "element-ui";
import { mapGetters } from "vuex";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    ...mapGetters({
      genders: "getGenders",
      maritial_statuses: "getMaritalStatuses",
      religions: "getReligions",
      model: "getPatient"
    }),
    age: function() {
      const getAge = birthDate =>{
          if(typeof(birthDate) == 'string'){
            birthDate = birthDate.split('-');
            return Math.floor((new Date() - new Date(birthDate[0], birthDate[1], birthDate[2]).getTime()) / 3.15576e10);        
          }
          else{
            return Math.floor((new Date() - birthDate.getTime()) / 3.15576e10);
          }
      }

      if (this.model.dob) return getAge(this.model.dob);
      else return null;
    }
  },
  data() {
    return {
      modelValidations: {
        name: {
          required: true
        },
        phone: {
          required: true,
          min: 8,
          max: 16
        },
        alt_phone: {
          required: false,
          min: 8,
          max: 16
        },
        email: {
          required: true,
          email: true
        },
        dob: {
          required: true
        },
        gender: {
          required: true
        },
        father_name: {
          required: true
        },
        mother_name: {
          required: true
        },
        maritial_status: {
          requried: true
        },
        religions: {
          required: true
        },
        password: {
          required: false,
          min: 6
        },
        ref_name: {
          required: true
        },
        ref_contact: {
          required: true,
          min: 8,
          max: 16
        },
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        let m = {
        patient_id: this.model.patient_id,
        user_id: this.model.user_id,
        name: this.model.name,
        phone: this.model.phone,
        alt_phone: this.model.alt_phone,
        email: this.model.email,
        dob: this.model.dob,
        gender: this.model.gender,
        father_name: this.model.father_name,
        mother_name: this.model.mother_name,
        spouse_name: this.model.spouse_name,
        maritial_status: this.model.maritial_status,
        religion: this.model.religion,
        password: this.model.password
      };
        this.$emit("on-validated", res, m);
        return res;
      });
    }
  }
};
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
