<template>
  <div v-if="model">
    <div class="row justify-content-center">
      
      <div class="form-group col-4" v-if="false">
        <label for>Blood Group</label>
        <fg-input
          name="blood_group"
          v-model="model.blood_group"
          v-validate="modelValidations.blood_group"
          :error="getError('blood_group')"
        >
          <el-select
            name="blood_group"
            class="select-defaul"
            placeholder="Pick a Blood Group"
            v-model="model.blood_group"
          >
            <el-option
              v-for="option in local.bloods"
              class="select-success"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

      <div class="form-group col-4" v-if="false">
        <label for>Height</label>
        <fg-input
          type="number"
          name="height"
          placeholder="Height"
          v-model="model.height"
          v-validate="modelValidations.height"
          :error="getError('height')"
        ></fg-input>
      </div>

      <div class="form-group col-4" v-if="false">
        <label for>Weight</label>
        <fg-input
          type="number"
          name="weight"
          placeholder="Weight"
          v-model="model.weight"
          v-validate="modelValidations.weight"
          :error="getError('weight')"
        ></fg-input>
      </div>

      <div class="form-group col-3">
        <label for>Billing Address</label>
        <fg-input
          type="text"
          name="billing_address_line"
          placeholder="Billing Address"
          v-model="model.billing_address_line"
          v-validate="modelValidations.billing_address_line"
          :error="getError('billing_address_line')"
        ></fg-input>
      </div>

      <div class="form-group col-3">
        <label for>Billing Landmark</label>
        <fg-input
          type="text"
          name="billing_landmark"
          placeholder="Billing Landmark"
          v-model="model.billing_landmark"
          v-validate="modelValidations.billing_landmark"
          :error="getError('billing_landmark')"
        ></fg-input>
      </div>

      <div class="form-group col-2">
        <label for>Billing Country</label>
        <fg-input
          name="billing_country"
          type="text"
          v-model="model.billing_country"
          v-validate="modelValidations.billing_country"
          :error="getError('billing_country')"
        >
          <el-select
            name="billing_country"
            v-model="model.billing_country"
            filterable
            default-first-option
            placeholder="Choose Billing Country"
          >
            <el-option
              v-for="item in countries"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

      <div class="form-group col-2">
        <label for>Billing State</label>
        <fg-input
          name="billing_state"
          type="text"
          v-model="model.billing_state"
          v-validate="modelValidations.billing_state"
          :error="getError('billing_state')"
        >
          <el-select
            v-if="model.billing_country == 'India'"
            name="billing_state"
            v-model="model.billing_state"
            filterable
            default-first-option
            placeholder="Choose Billing State"
          >
            <el-option
              v-for="item in states"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

      <div class="form-group col-2">
        <label for>Billing Pincode</label>
        <fg-input
          type="number"
          name="billing_pin"
          placeholder="Billing Pincode"
          v-model="model.billing_pin"
          v-validate="modelValidations.billing_pin"
          :error="getError('billing_pin')"
        ></fg-input>
      </div>

      <div class="form-group col-3">
        <label for>Shipping Address</label>
        <p-button type="neutral" @click="copyBilling2Shipping()">copy as above</p-button>
        <fg-input
          type="text"
          name="shipping_address_line"
          placeholder="Shipping Address"
          v-model="model.shipping_address_line"
          v-validate="modelValidations.shipping_address_line"
          :error="getError('shipping_address_line')"
        ></fg-input>
      </div>

      <div class="form-group col-3">
        <label for>Shipping Landmark</label>
        <fg-input
          type="text"
          name="shipping_landmark"
          placeholder="Shipping Landmark"
          v-model="model.shipping_landmark"
          v-validate="modelValidations.shipping_landmark"
          :error="getError('shipping_landmark')"
        ></fg-input>
      </div>

      <div class="form-group col-2">
        <label for>Shipping Country</label>
        <fg-input
          name="shipping_country"
          type="text"
          v-model="model.shipping_country"
          v-validate="modelValidations.shipping_country"
          :error="getError('shipping_country')"
        >
          <el-select
            name="shipping_country"
            v-model="model.shipping_country"
            filterable
            default-first-option
            placeholder="Choose Shipping Country"
          >
            <el-option
              v-for="item in countries"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

      <div class="form-group col-2">
        <label for>Shipping State</label>
        <fg-input
          name="shipping_state"
          type="text"
          v-model="model.shipping_state"
          v-validate="modelValidations.shipping_state"
          :error="getError('shipping_state')"
        >
          <el-select
            v-if="model.shipping_country == 'India'"
            name="shipping_state"
            v-model="model.shipping_state"
            filterable
            default-first-option
            placeholder="Choose Shipping State"
          >
            <el-option
              v-for="item in states"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

      <div class="form-group col-2">
        <label for>Shipping Pincode</label>
        <fg-input
          type="number"
          name="shipping_pin"
          placeholder="Shipping Pincode"
          v-model="model.shipping_pin"
          v-validate="modelValidations.shipping_pin"
          :error="getError('shipping_pin')"
        ></fg-input>
      </div>

      <!-- <div class="form-group col-2">
        <label for>Shipping Country</label>
        <fg-input
          type="text"
          name="shipping_country"
          placeholder="Shipping Country"
          v-model="model.shipping_country"
          v-validate="modelValidations.shipping_country"
          :error="getError('shipping_country')"
        ></fg-input>
      </div> -->

      

      <div class="form-group col-4">
        <label for>Pref. Languages</label>
        <el-select
          v-model="model.preffered_language"
          multiple
          filterable
          default-first-option
          placeholder="Choose languages"
        >
          <el-option
            v-for="(item, index) in local.preffered_language"
            :key="item.index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="form-group col-4">
        <label for>Location</label>
        <fg-input
          type="text"
          name="location_id"
          v-model="model.location_id"
          v-validate="modelValidations.location_id"
          :error="getError('location_id')"
        >
        <el-select
          name="location_id"
          class="select-defaul"
          placeholder="Clinic Location"
          v-model="model.location_id"
        >
          <el-option
            v-for="option in locations"
            class="select-success"
            :value="option.id"
            :label="`${option.location_id} - ${option.name}`"
            :key="option.id"
          ></el-option>
        </el-select>
        </fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Known Diseases</label>
        <el-select
          v-model="model.known_diseases"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="Choose diseases"
        >
          <el-option
            v-for="item in diseases"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
      </div>

      <div class="form-group col-4" v-if="true">
        <label for>Lead</label>
        <div>
          <p-switch v-model="model.isLead" on-text="Yes" off-text="No"></p-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Upload, DatePicker, Select, Option } from "element-ui";
import { Switch } from "src/components/UIComponents";
import { mapGetters } from "vuex";
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  computed: {
    ...mapGetters({
      locations: "getLocations",
      model: "getPatient",
      diseases: "getDiseases",
      countries: "getCountries",
      states: "getStates"
    })
  },
  mounted() {
    this.$store.dispatch("fetchLocationsAll");
    this.$store.dispatch("fetchDiseasesAll");
  },
  data() {
    return {
      local: {
        bloods: [
          { value: "A+", label: "A+" },
          { value: "B+", label: "B+" },
          { value: "A-", label: "A-" },
          { value: "B-", label: "B-" },
          { value: "O+", label: "O+" },
          { value: "O-", label: "O-" },
          { value: "AB+", label: "AB+" },
          { value: "AB-", label: "AB-" }
        ],
        preffered_languages: [],
        preffered_language: [
          { value: "English", label: "English" },
          { value: "Hindi", label: "Hindi" },
          { value: "Kannada", label: "Kannada" },
          { value: "Tamil", label: "Tamil" },
          { value: "Malayalam", label: "Malayalam" }
        ],
      },
      modelValidations: {
        password: {
          required: true,
          min: 6
        },
        location_id: {
          required: true
        },
        blood_group: {
          required: true
        },
        height: {
          required: true,
          max: 3
        },
        weight: {
          required: true,
          max: 3
        },
        address_line: {
          required: true
        },
        state: {
          required: true
        },
        pin: {
          required: true,
          min: 6,
          max: 6
        },
        country: {
          required: true
        },
        billing_address_line: {
          required: true
        },
        billing_state: {
          required: true
        },
        billing_pin: {
          required: false,
          min: 6,
          max: 6
        },
        billing_country: {
          required: true
        },
        shipping_address_line: {
          required: true
        },
        shipping_state: {
          required: true
        },
        shipping_pin: {
          required: false,
          min: 6,
          max: 6
        },
        shipping_country: {
          required: true
        },
        preffered_language: {
          required: true
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {  
        let m = {
        isLead: false,
        password: this.model.password,
        location_id: this.model.location_id,
        created_by: this.model.created_by,
        role: this.model.role,
        blood_group: this.model.blood_group,
        height: this.model.height,
        weight: this.model.weight,
        billing_address_line: this.model.billing_address_line,
        billing_landmark: this.model.billing_landmark,
        billing_state: this.model.billing_state,
        billing_pin: this.model.billing_pin,
        billing_country: this.model.billing_country,
        shipping_address_line: this.model.shipping_address_line,
        shipping_landmark: this.model.shipping_landmark,
        shipping_state: this.model.shipping_state,
        shipping_pin: this.model.shipping_pin,
        shipping_country: this.model.shipping_country,
        known_diseases: this.model.known_diseases,
        preffered_language: this.model.preffered_language
      };
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
    copyBilling2Shipping(){
      this.model.shipping_address_line =   this.model.billing_address_line
      this.model.shipping_landmark =   this.model.billing_landmark
      this.model.shipping_state =  this.model.billing_state
      this.model.shipping_pin =  this.model.billing_pin
      this.model.shipping_country =  this.model.billing_country
    }
  }
};
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
